import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../../../style';
import {
  Typography,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import {
  SaveOutlined,
  ClearOutlined,
  Edit,
  DeleteOutline
} from '@material-ui/icons';

import validate from 'validate.js';
import { connect } from 'react-redux';
import {
  getAssessmentPlanTemplates,
  getTopicTextByAssessmentId,
  AddAssessmentPlanTopicText,
  UpdateAssessmentPlanTopicText,
  DeleteAssessmentPlanTemplateTopic
} from 'services/providers/action';

const MyTemplates = props => {
  const { classes, ehrID, userID, assessmentPlanTopic, getTopicTextByAssessmentId } = props;
  const [loading, setLoading] = useState(true);
  const [assessmentPlan, setAssessmentPlan] = useState(null);
  const [page, setPage] = useState(0);
  const [isFetching, setIsFetching] = useState(false)

  const [handleEditTopicText, setHandleEditTopicText] = useState({ showTopicTextEdit: false });
  const initialUserState = {
    id: null,
    referencedata: false,
    topic: "",
    topicText: [],
    isValid: false,
    touched: {},
    errors: {}
  };

  const schema = {
    topicText: {
      presence: { allowEmpty: false }
    }
  }

  const [values, setValues] = useState(initialUserState);

  useEffect(() => {
    if (props.assessmentPlanTemplates && assessmentPlanTopic) {
      const assessmentPlan = props.assessmentPlanTemplates.data.filter((item) => item.topic === assessmentPlanTopic);
       getTopicTextByAssessmentId(ehrID, assessmentPlan[0].id, 0, 10).then(res => {
        let data = {...assessmentPlan[0], topicText : res?.data }
        setAssessmentPlan(data);
        console.log(res.total, res?.data, data.topicText.length)
        setPage(1)
        if(res?.total > res?.data.length){
          setIsFetching(true)
        }
      })
      setLoading(false);
    }
  }, [assessmentPlanTopic])


  const loadFunc = async (e) => {
    if (isFetching) {
      const res = await getTopicTextByAssessmentId(ehrID, assessmentPlan.id, page * 10, 10)
      let data = {...assessmentPlan, topicText: [...assessmentPlan.topicText, ...res?.data] }
      setAssessmentPlan(data);
      if (res.total == data.topicText.length) {
        setIsFetching(false);
      }
      if (res.total > data.topicText.length) {
        setIsFetching(true);
        setPage(page + 1);
        return
      }
    }
  };

  const editTopicText = (idx) => {
    let showTopicTextEdit = handleEditTopicText.showTopicTextEdit ? handleEditTopicText.showTopicTextEdit.slice() : [];
    showTopicTextEdit[idx] = true;
    setHandleEditTopicText({ showTopicTextEdit: showTopicTextEdit });
  }

  const handleChange = (field, value, idx) => {
    const topic = { ...assessmentPlan };
    let allTexts = topic.topicText;
    let selectedData = allTexts[idx];
    selectedData.text = value;
    allTexts[idx] = selectedData;
    topic.topicText = allTexts;
    setAssessmentPlan(topic);
  };

  const updateText = (assessmentPlan, idx) => {
    const topic = { ...assessmentPlan };
    let allTexts = topic.topicText;
    let selectedData = allTexts[idx];
    props.UpdateAssessmentPlanTopicText(ehrID, userID, selectedData).then((res) => {
      closeEditTopicText(assessmentPlan.id, idx);
    });
  }

  const closeEditTopicText = (id, idx) => {
    let showTopicTextEdit = handleEditTopicText.showTopicTextEdit ? handleEditTopicText.showTopicTextEdit.slice() : [];
    showTopicTextEdit[idx] = false;
    setHandleEditTopicText({ showTopicTextEdit: showTopicTextEdit });

    getTopicTextByAssessmentId(ehrID, assessmentPlan[0].id, 0, 10).then(res => {
      let data = {...assessmentPlan[0], topicText : res?.data }
      setAssessmentPlan(data);
      setPage(1)
      if(res?.total > res?.data.length){
        setIsFetching(true)
      }
    })
  }

  const deleteTopicText = (assessmentPlan, index) => {
    const topic = { ...assessmentPlan };
    let allTexts = topic.topicText;
    let selectedData = allTexts[index];
    topic.topicText.splice(index, 1);   
    props.DeleteAssessmentPlanTemplateTopic(ehrID, userID, selectedData.id).then((res) => {
      setAssessmentPlan(topic);
    });
  }

  const handleTextChange = (field, value) => {
    const newState = { ...values };
    newState[field] = value;
    newState.touched[field] = true;
    const errors = validate(newState, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;
    setValues(newState);
  }

  const addTopicText = () => {
    if (values.isValid) {
      const topic = { ...assessmentPlan };
      topic.topicText.push({ text: values.topicText });
      props.AddAssessmentPlanTopicText(ehrID, userID, topic, values.topicText).then((res) => {
        setAssessmentPlan(topic);
        setValues(initialUserState);
      });
    }
  }

  return (
    <div>
      {assessmentPlanTopic !== null ?
        (<div className={classes.templateWrap}>
          <div className={classes.templateHead}>
            <Typography>{assessmentPlanTopic}</Typography>
          </div>
          <div className={classes.templateBody}>
            <PerfectScrollbar onYReachEnd={loadFunc}>
              <div style={{ maxHeight: 'calc(100vh - 298px)' }}>
                <Table className={classes.templateTable}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '100%' }}>
                        <TextField
                          id="outlined-basic"
                          placeholder="Add New Text"
                          variant="outlined"
                          className={classes.textBox}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline
                            }
                          }}
                          value={values.topicText}
                          onChange={event =>
                            handleTextChange("topicText", event.target.value)
                          }
                        />
                      </TableCell>
                      <TableCell align="center" style={{ whiteSpace: 'nowrap', maxWidth: 100 }}>
                        <IconButton disabled={!values.isValid} size="small" color="primary" onClick={() => { addTopicText() }}>
                          <SaveOutlined />
                        </IconButton>
                        <IconButton size="small" onClick={() => { setValues(initialUserState) }}>
                          <ClearOutlined style={{ color: '#DE5555' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {/* <TableBody> */}
                  {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <CircularProgress size={24} />
                    </div>) : assessmentPlan && assessmentPlan?.topicText && assessmentPlan?.topicText?.length > 0 ?
                      (assessmentPlan.topicText.map((text, idx) => (
                        <TableHead>
                          <TableRow>
                            {handleEditTopicText.showTopicTextEdit && handleEditTopicText.showTopicTextEdit[idx] ? [
                              <TableCell>
                                <TextField
                                  id="outlined-basic"
                                  placeholder="Add New Topic"
                                  variant="outlined"
                                  className={classes.textBox}
                                  InputProps={{
                                    classes: {
                                      notchedOutline: classes.notchedOutline
                                    }
                                  }}
                                  value={text.text}
                                  onChange={event =>
                                    handleChange("text", event.target.value, idx)
                                  }
                                />
                              </TableCell>,
                              <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                                <IconButton size="small" color="primary" onClick={() => { updateText(assessmentPlan, idx) }}>
                                  <SaveOutlined />
                                </IconButton>
                                <IconButton size="small" onClick={() => { closeEditTopicText(assessmentPlan.id, idx) }}>
                                  <ClearOutlined style={{ color: '#DE5555' }} />
                                </IconButton>
                              </TableCell>] :
                              [< TableCell >
                                {text.text}
                              </TableCell>,
                              <TableCell width={72} align="center">
                                <IconButton size="small" onClick={() => { editTopicText(idx) }}>
                                  <Edit color="primary" />
                                </IconButton>
                                <IconButton size="small" onClick={() => { deleteTopicText(assessmentPlan, idx) }}>
                                  <DeleteOutline style={{ color: '#DE5555' }} />
                                </IconButton>
                              </TableCell>]}
                          </TableRow>
                        </TableHead>
                      ))
                      ) : (
                        <TableRow className={classes.tableRow}>
                        <TableCell
                          className={classes.tableCell}
                          colSpan={10}
                          style={{ textAlign: 'center' }}
                        >
                            No records found
                        </TableCell>
                      </TableRow>)}
                  
                </Table>
              </div>
            </PerfectScrollbar>
          </div>
        </div>) : null
      }
    </div >
  );
};
MyTemplates.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getAssessmentPlanTemplates,
  AddAssessmentPlanTopicText,
  UpdateAssessmentPlanTopicText,
  DeleteAssessmentPlanTemplateTopic,
  getTopicTextByAssessmentId
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  assessmentPlanTemplates: state.providers && state.providers.assessmentPlanTemplates
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyTemplates))
);
