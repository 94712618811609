import React from 'react';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../../../style';
import DraggableList from './DraggableList';
import { connect } from 'react-redux';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Button
} from '@material-ui/core';
import {
  CheckOutlined,
  ClearOutlined,
  Edit,
  DragIndicatorOutlined,
  AddOutlined,
  ChevronLeftOutlined
} from '@material-ui/icons';
const MyTemplates = props => {
  const { classes,ptPastDiagnosis, patientInfo } = props;

  const handleCopy = item => {
    props.handleCopy(item);
  };

  return (
    <div className={classes.templateWrap}>
      <div className={classes.templateBody}>
        <PerfectScrollbar>
          <div
            style={{
              maxHeight: 'calc(100vh - 236px)',
              minHeight: 'calc(100vh - 350px)'
            }}>
            <Table className={classes.templateTable}>
              <TableHead>
                <TableRow>
                  <TableCell colspan={2}>Past Diagnosis</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ptPastDiagnosis &&
                  ptPastDiagnosis.length > 0 &&
                  ptPastDiagnosis
                    .filter(c => c.codeType === 'diagnosis' )
                    .map(item => {
                      return (
                        <TableRow>
                          <TableCell style={{ whiteSpace: 'nowrap' }}>
                            <IconButton color="primary" size="small">
                              <ChevronLeftOutlined
                                onClick={() => handleCopy(item)}
                              />
                            </IconButton>
                            {item.code}
                          </TableCell>
                          <TableCell>
                            <div className={classes.ellipsisMode}>
                              <Typography>{item.description}</Typography>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </div>
    </div>
  );
};
MyTemplates.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {};
const mapStateToProps = state => ({ 
  patientInfo: state.soap && state.soap.patientInfo,  
  ptPastDiagnosis: state.soap && state.soap.ptPastDiagnosis, 
});


export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyTemplates))
);
 
