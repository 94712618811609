import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../../../style';
import {
  Typography,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Link,
  CircularProgress
} from '@material-ui/core';
import {
  SaveOutlined,
  ClearOutlined,
  Edit,
  DeleteOutline
} from '@material-ui/icons';

import validate from 'validate.js';
import { connect } from 'react-redux';
import {
  getAssessmentPlanTemplates,
  AddAssessmentPlanTemplate,
  UpdateAssessmentPlanTemplate,
  DeleteAssessmentPlanTemplate
} from 'services/providers/action';

const MyTemplates = props => {
  const { classes, ehrID, userID, ...rest } = props;
  const initialUserState = {
    id: null,
    referencedata: false,
    topic: '',
    topicText: [],
    isValid: false,
    touched: {},
    errors: {}
  };

  const schema = {
    topic: {
      presence: { allowEmpty: false }
    }
  };
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState(initialUserState);
  const [assessmentPlanTopics, setAssessmentPlanTopics] = useState(null);
  const [
    handleEditAssessmentPlanTopic,
    setHandleEditAssessmentPlanTopic
  ] = useState({ showAssessmentPlanTopicEdit: false });

  useEffect(() => {
    if (props.assessmentPlanTopics !== null) {
      setAssessmentPlanTopics(props.assessmentPlanTopics);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [props.assessmentPlanTopics]);

  const editAssessmentPlanTopic = id => {
    let showAssessmentPlanTopicEdit = handleEditAssessmentPlanTopic.showAssessmentPlanTopicEdit
      ? handleEditAssessmentPlanTopic.showAssessmentPlanTopicEdit.slice()
      : [];
    showAssessmentPlanTopicEdit[id] = true;
    setHandleEditAssessmentPlanTopic({
      showAssessmentPlanTopicEdit: showAssessmentPlanTopicEdit,
      editId: id
    });
  };

  const handleChange = (field, value, id) => {
    let allDatas = [...assessmentPlanTopics];
    const index = assessmentPlanTopics.findIndex(c => c.id === id);
    if (index != -1) {
      let selectedData = assessmentPlanTopics[index];
      selectedData.topic = value;
      allDatas[index] = selectedData;
      setAssessmentPlanTopics(allDatas);
    }
  };

  const updateAssessmentPlanTopic = assessmentPlan => {
    props
      .UpdateAssessmentPlanTemplate(ehrID, userID, assessmentPlan)
      .then(res => {
        closeEditAssessmentPlanTopic(assessmentPlan.id);
      });
  };

  const closeEditAssessmentPlanTopic = id => {
    let showAssessmentPlanTopicEdit = handleEditAssessmentPlanTopic.showAssessmentPlanTopicEdit
      ? handleEditAssessmentPlanTopic.showAssessmentPlanTopicEdit.slice()
      : [];
    showAssessmentPlanTopicEdit[id] = false;
    setHandleEditAssessmentPlanTopic({
      showAssessmentPlanTopicEdit: showAssessmentPlanTopicEdit
    });

    props.getAssessmentPlanTemplates(ehrID, userID).then(res => {
      if (res && res.data.length > 0) {
        setAssessmentPlanTopics(res.data);
      }
    });
  };

  const deleteAssessmentPlanTopic = id => {
    props.DeleteAssessmentPlanTemplate(ehrID, userID, id).then(res => {
      closeEditAssessmentPlanTopic(id);
    });
  };

  const handleTopicChange = (field, value) => {
    const newState = { ...values };
    newState[field] = value;
    newState.touched[field] = true;
    const errors = validate(newState, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;
    setValues(newState);
  };

  const addAssessmentPlanTopic = () => {
    if (values.isValid) {
      props.AddAssessmentPlanTemplate(ehrID, userID, values).then(res => {
        setValues(initialUserState);
        props.getAssessmentPlanTemplates(ehrID, userID).then(res => {
          if (res && res.data.length > 0) {
            setAssessmentPlanTopics(res.data);
          }
        });
      });
    }
  };

  const setTopicText = topicText => {
    props.setTopicText(topicText);
  };

  return (
    <div className={classes.templateWrap}>
      <div className={classes.templateHead}>
        <Typography>My Templates</Typography>
      </div>
      <div className={classes.templateBody}>
        <PerfectScrollbar>
          <div style={{ maxHeight: 'calc(100vh - 298px)' }}>
            <Table className={classes.templateTable}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '100%' }}>
                    <TextField
                      className={classes.textBox}
                      id="outlined-basic"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline
                        }
                      }}
                      onChange={event =>
                        handleTopicChange('topic', event.target.value)
                      }
                      placeholder="Add New Topic"
                      value={values.topic}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                    <IconButton
                      color="primary"
                      disabled={!values.isValid}
                      onClick={() => {
                        addAssessmentPlanTopic();
                      }}
                      size="small">
                      <SaveOutlined />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setValues(initialUserState);
                      }}
                      size="small">
                      <ClearOutlined style={{ color: '#DE5555' }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              {/* <TableBody> */}
              {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress size={24} />
                </div>
              ) : assessmentPlanTopics && assessmentPlanTopics.length > 0 ? (
                assessmentPlanTopics.map(assessmentPlan => (
                  <TableHead>
                    <TableRow>
                      {handleEditAssessmentPlanTopic.showAssessmentPlanTopicEdit &&
                      handleEditAssessmentPlanTopic.showAssessmentPlanTopicEdit[
                        assessmentPlan.id
                      ]
                        ? [
                            <TableCell>
                              <TextField
                                className={classes.textBox}
                                id="outlined-basic"
                                InputProps={{
                                  classes: {
                                    notchedOutline: classes.notchedOutline
                                  }
                                }}
                                onChange={event =>
                                  handleChange(
                                    'topic',
                                    event.target.value,
                                    assessmentPlan.id
                                  )
                                }
                                placeholder="Add New Topic"
                                value={assessmentPlan.topic}
                                variant="outlined"
                              />
                            </TableCell>,
                            <TableCell
                              align="center"
                              style={{
                                whiteSpace: 'nowrap',
                                maxWidth: 100
                              }}>
                              <IconButton
                                color="primary"
                                onClick={() => {
                                  updateAssessmentPlanTopic(assessmentPlan);
                                }}
                                size="small">
                                <SaveOutlined />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  closeEditAssessmentPlanTopic(
                                    assessmentPlan.id
                                  );
                                }}
                                size="small">
                                <ClearOutlined style={{ color: '#DE5555' }} />
                              </IconButton>
                            </TableCell>
                          ]
                        : [
                            <TableCell className={classes.letterFont}>
                              <Link
                                component={'button'}
                                onClick={() => {
                                  setTopicText(assessmentPlan.topic);
                                }}>
                                {assessmentPlan.topic}
                              </Link>
                            </TableCell>,
                            <TableCell align="center" width={72}>
                              <IconButton
                                onClick={() => {
                                  editAssessmentPlanTopic(assessmentPlan.id);
                                }}
                                size="small">
                                <Edit color="primary" />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  deleteAssessmentPlanTopic(assessmentPlan.id);
                                }}
                                size="small"
                                style={{
                                  visibility:
                                    assessmentPlan?.topicText?.length === 0
                                      ? 'visible'
                                      : 'hidden'
                                }}>
                                <DeleteOutline style={{ color: '#DE5555' }} />
                              </IconButton>
                            </TableCell>
                          ]}
                    </TableRow>
                  </TableHead>
                ))
              ) : (
                <TableRow className={classes.tableRow}>
                  <TableCell
                    className={classes.tableCell}
                    colSpan={10}
                    style={{ textAlign: 'center' }}>
                    No records of assessment &amp; plan template - User Defined
                  </TableCell>
                </TableRow>
              )}
              {/* </TableBody> */}
            </Table>
          </div>
        </PerfectScrollbar>
      </div>
    </div>
  );
};
MyTemplates.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getAssessmentPlanTemplates,
  AddAssessmentPlanTemplate,
  UpdateAssessmentPlanTemplate,
  DeleteAssessmentPlanTemplate
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyTemplates))
);
